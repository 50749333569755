<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      :title="'Expenses'"
    ></subpage-header>
    <div class="subpage-information-area-margin">
      <hr style="margin-bottom: 20px;" class="is-hidden-touch">
      <div v-show="true">
        <div class="box bunya-box no-shadow empty-state large">
            <div class="no-content">
              <img class="large" src="../assets/emptyStates/empty_expenses.svg">
              <h3 class="large">We'll be right back.</h3>
              <h5 class="large">This feature is being upgraded and will be back February 1st, 2021.</h5>
            </div>
        </div>
      </div>
    </div>
    <div class="box bunya-box no-shadow is-hidden">
      <div class="columns">
        <div class="column">
          <h1>Quotes Content Goes Here</h1>
          <h4>( Same as Invoices Page )</h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import SubpageHeader from '@/components/SubpageHeader'

export default {
  name: 'Tables',
  components: {
    SubpageHeader
  },
  data () {
    return {
      isHiddenList: true,
      isClientModalActive: false,
      primaryAction: {
        name: 'Add Quote',
        icon: null,
        emit: 'client-create'
      }
    }
  },
  methods: {
    clientCreate () {
      this.$router.push('/quotes/create')
    }
  }
}
</script>
